



































import { mapActions, mapGetters } from "vuex";

import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { defineComponent } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
export default defineComponent({
  name: "TestTypesModal",
  components: { BaseModalForm },
  props: {
    type: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    data: {
      name: "",
      description: "",
      isActive: true,
    },
  }),
  computed: {
    ...mapGetters("testTypes", ["getType"]),
    readonly(): boolean {
      return this.type === ModalType.Display;
    },
  },
  methods: {
    ...mapActions("testTypes", ["addType", "editType"]),
    setData() {
      this.data = {
        name: (this as any).getType.name || "",
        description: (this as any).getType.description || "",
        isActive: (this as any).getType.isActive || true,
      };
    },
    async handleSubmit() {
      if ((this as any).$refs.connectForm.validate()) {
        this.$store.commit("testTypes/SET_TYPE", this.data);
        if (this.type === ModalType.Add) {
          (this as any).addType();
          this.$emit("close");
        } else if (this.type === ModalType.Edit) {
          (this as any).editType();
          this.$emit("close");
        } else {
          openNotification(this.$store, NotificationItemType.Error, "Det skjedde en feil");
        }
      }
    },
  },
  mounted() {
    this.setData();
  },
});
