



































import { mapGetters, mapActions } from "vuex";
import BaseModal from "@/components/shared/BaseModal.vue";
import TestTypesModal from "@/components/administration/test-types/TestTypesModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { VSwitch } from "vuetify/lib";
import { defineComponent } from "@vue/composition-api";

interface ComponentData {
  search: string;
  showModal: boolean;
  modalHeadline: string;
  modalType: string;
}

export default defineComponent({
  name: "TestTypePage",
  components: {
    BaseTooltipIconButton,
    BaseTableFiltered,
    BaseModal,
    TestTypesModal,
  },
  data(): ComponentData {
    return {
      search: "",
      showModal: false,
      modalHeadline: "",
      modalType: "",
    };
  },
  computed: {
    ...mapGetters("testTypes", ["getTypes"]),
    headers() {
      return [
        { text: "Navn", value: "name" },
        {
          text: "Beskrivelse",
          value: "description",
        },
        { text: "Aktiv", value: "isActive" },
        { text: "Handlinger", value: "actions" },
      ];
    },
    filter() {
      return [
        {
          component: VSwitch,
          value: "isActive",
          staticClass: "mx-3",
          default: true,
          attrs: {
            label: "Vis inaktive",
            inset: true,
          },
          apply: (value: any, model: any) => model || value,
        },
      ];
    },
  },
  methods: {
    ...mapActions("testTypes", ["fetchTypes"]),
    newType() {
      this.$store.commit("testTypes/SET_TYPE", {});

      this.showModal = true;
      this.modalHeadline = "Legg til testtype";
      this.modalType = "add";
    },
    displayType(item: any) {
      this.$store.commit("testTypes/SET_TYPE", item);
      this.showModal = true;
      this.modalHeadline = item.name;
      this.modalType = "display";
    },
    editType(item: any) {
      this.$store.commit("testTypes/SET_TYPE", item);

      this.showModal = true;
      this.modalHeadline = `Rediger ${item.name}`;
      this.modalType = "edit";
    },
  },
  async mounted() {
    await (this as any).fetchTypes();
  },
});
